






















































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validationRules';
import IUserForm from '@/types/IUserForm';
import IUser from '@/types/IUser';
import AdminUsersService from '@/services/AdminUsersService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'UserForm'})
export default class UserForm extends Vue {
  loading: boolean = false;

  form: IUserForm = {
    firstName: '',
    lastName: '',
    password: '',
  };

  rules = {
    firstName: [required],
    lastName: [required],
    password: [],
  };

  @Prop()
  user!: IUser;

  @Watch('user.id')
  onUserChange(value: number | string | undefined) {
    if (!value) {
      this.form = {
        firstName: '',
        lastName: '',
        password: '',
      };
      // @ts-ignore
      this.$refs.form.resetValidation();
    } else {
      this.form = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: '',
      };
    }
  }

  async save() {
    try {
      this.loading = true;
      // @ts-ignore
      if (this.$refs.form.validate()) {
        await AdminUsersService.update(this.user.id, this.form);
        this.$emit('edited');
        notify(NotificationTypes.success, this.$t('SYSTEM_SAVED'));
      }
    } catch (e) {
      resolveError(e, 'edit');
    } finally {
      this.loading = false;
    }
  }

  @Emit('close')
  close() {
    return;
  }

}
